import { faKey, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { cta, fontLight } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Input from '@elements/Input';
import Title from '@elements/Title';
import { usePasswordForgotnMutation } from '@graphql/generated/graphql';

const Component = styled.div`
  width: 320px;
  padding: ${space * 5}px ${space * 3}px;
  text-align: center;

  input {
    font-size: 16px;
  }

  p {
    color: ${fontLight};
  }

  .icon {
    color: ${cta};
  }

  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${space}px;
    margin-top: ${space * 2}px;

    & > * {
      flex-grow: 1;
    }
  }

  ${tablet} {
    padding: ${space * 7}px ${space * 5}px;
    width: 450px;
  }
`;

const ForgotDialog = () => {
  const [submit, setSubmit] = useState(false);
  const [passwordForgot, { loading }] = usePasswordForgotnMutation();
  const formMethods = useForm<{ email: string }>({ mode: 'onChange' });
  const { handleSubmit, formState, setValue, getValues } = formMethods;
  const [isUserInput, setIsUserInput] = useState(false);

  useEffect(() => {
    // Detect autofill on loading
    setTimeout(() => {
      if (getValues('email')) {
        setIsUserInput(true);
      }
    }, 300);
  }, [getValues]);

  const handleUserInput = () => {
    setIsUserInput(true);
  };

  const onSubmit = (data: { email: string }) => {
    if (!isUserInput) {
      return;
    }

    passwordForgot({
      variables: { email: data.email },
      onCompleted: () => setSubmit(true),
    });
  };

  if (submit) {
    return (
      <Component>
        <FontAwesomeIcon icon={faEnvelope} size="3x" className="icon" />
        <Title size="nm" as="h3" className="mt-16">
          Controleer je e-mail
        </Title>
        <p className="mt-8">
          Indien er een account bestaat met dit mailadres ontvang je een link om
          je wachtwoord opnieuw in te stellen.
        </p>
        <Link href="#login" passHref>
          <Button className="mt-16">Login</Button>
        </Link>
      </Component>
    );
  }

  return (
    <Component>
      <FontAwesomeIcon icon={faKey} size="3x" className="icon" />
      <Title size="nm" as="h3" className="mt-16">
        Nieuw wachtwoord aanvragen
      </Title>
      <p className="mt-8">
        Geen probleem. Vul hieronder je e-mailadres in, dan sturen wij je een
        link toe waarmee je een nieuw wachtwoord kunt aanmaken.
      </p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="email"
            label="E-mailadres"
            type="email"
            className="mt-16"
            required
            onChange={handleUserInput}
          />
          <div className="actions">
            <Link href="#login" passHref>
              <Button set="tertiary">Terug naar login</Button>
            </Link>
            <Button type="submit" disabled={loading || !formState.isValid}>
              Wachtwoord opvragen
            </Button>
          </div>
        </form>
      </FormProvider>
    </Component>
  );
};

export default ForgotDialog;
