import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import DialogBookChoice from '@components/DialogBookChoice';
import DialogBooks from '@components/DialogBooks';
import DialogForgot from '@components/DialogForgot';
import DialogInvite from '@components/DialogInvite';
import DialogLogin from '@components/DialogLogin';
import DialogSignup from '@components/DialogSignup';
import { lineLight, fontMedium } from '@constants/colors';
import { laptop } from '@constants/media-queries';
import {
  books,
  forgot,
  invite,
  login,
  signup,
  bookChoice,
} from '@constants/routes';
import { space } from '@constants/spaces';
import zindex from '@utils/zindex';

const Component = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: rgb(10, 31, 68, 0.5);
  z-index: ${zindex('dialog')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  transition: opacity 150ms ease-out;
  will-change: opacity;
`;

const Container = styled.div`
  max-width: 95%;
  margin: ${space * 8}px ${space * 3}px ${space * 14}px;
  position: relative;
  z-index: 1;
  background: white;
  border-radius: 16px;
  border: 1px solid ${lineLight};
  box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.06),
    0px 3px 3px -1px rgba(10, 22, 70, 0.1);

  ${laptop} {
    margin: 90px 0;
  }
`;

const Close = styled.button`
  background: none;
  position: absolute;
  top: ${space * 1.5}px;
  right: ${space * 1.5}px;
  cursor: pointer;
  color: ${fontMedium};
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: default;
`;

type DialogProps = {
  open?: string;
  closeDialog?: () => void;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  closeOnBackgroundClick?: boolean;
};

const Dialog = ({
  open = '',
  closeDialog,
  children = null,
  showCloseButton = true,
  closeOnBackgroundClick = true,
}: DialogProps) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    const hashRoutes = [login, signup, books, forgot, invite, bookChoice];
    const hashes = router.asPath.match(/#([a-z0-9]+)/gi);
    const hash = hashes && hashes[0];
    if (!children) {
      if (hash && hashRoutes.indexOf(hash) > -1) {
        setIsOpen(hash);
      } else {
        setIsOpen('');
      }
    } else {
      if (open) {
        setIsOpen(open);
      } else {
        setIsOpen('');
      }
    }
  }, [children, open, router.asPath]);

  const dialog = () => {
    switch (isOpen) {
      case login:
        return <DialogLogin />;
      case signup:
        return <DialogSignup />;
      case forgot:
        return <DialogForgot />;
      case books:
        return <DialogBooks />;
      case invite:
        return <DialogInvite />;
      case bookChoice:
        return <DialogBookChoice />;
      default:
        null;
    }
  };

  const close = () => {
    setIsOpen('');
    router.push(router.asPath.replace(/#([a-z0-9]+)/gi, ''));
    closeDialog && closeDialog();
  };

  if (!children && !isOpen) return null;

  return (
    <Component open={!!isOpen}>
      <Container>
        {showCloseButton && (
          <Close onClick={close}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </Close>
        )}
        {children || dialog()}
      </Container>
      {closeOnBackgroundClick && <Background onClick={close} />}
    </Component>
  );
};

export default Dialog;
